import React, { useContext } from 'react';
import { Callout } from 'components';
// import { Row, Col } from 'react-flexbox-grid';
import { appContext } from 'providers/appProvider';
import DownloadBlock from 'components/DownloadBlock';
import ColorBlock from 'components/Content/ColorBlock';

function DownloadCopayCard({ className, ...props }) {
  const { appConfigs } = useContext(appContext);
  const brandNames = {
    NASCOBAL: appConfigs?.metaData?.brandNames?.nascobal,
    BARIACTIV: appConfigs?.metaData?.brandNames?.bariactiv,
    NUTRITION: appConfigs?.metaData?.brandNames?.nutrition,
  };
  return (
    <Callout className={`${className}`}>
      <ColorBlock blue id="colorblock" className="mt-15 mb-20">
        <DownloadBlock
          // DownloadFile="Crohns-Patient-Brochure.pdf"
          LinkCaption="DOWNLOAD COPAY CARD"
          ThumbnailImage="ns-copay-card-thumb.png"
          Link="https://nascobal-savings-card.azurewebsites.net/savings-card/"
          Direct
          className="copay-btn blue-button"
          footnote
          footnoteText="<sup>*</sup>Patients may redeem this offer ONLY when accompanied by a valid
            prescription. Offer is valid up to a maximum benefit of $150. Offer
            is not valid for patients whose prescriptions are reimbursed in
            whole or in part under Medicaid, Medicare, Medigap, VA, DoD,
            TRICARE, or any other federal or state programs (such as medical
            assistance programs) or where otherwise prohibited by law. Offer is
            not valid in VT or where prohibited in whole or in part. This offer
            may be amended or ended at any time without notice."
        >
          <h2 className="txt-uppercase">COPAY CARD</h2>
          <p className="no-bold">
            An affordable treatment for vitamin B
            <sub className="vitaminb12-sub">12</sub> deficiency.
          </p>
          <p>
            Eligible patients may get{' '}
            <span
              className="brandname-nascobal"
              dangerouslySetInnerHTML={{
                __html: brandNames.NASCOBAL,
              }}
            />{' '}
            <span className="blue-bold-text">for as little as $0</span> per
            month.<sup>*</sup>
          </p>
          {/*  <p className="footnote no-bold"></p> */}
        </DownloadBlock>
      </ColorBlock>
    </Callout>
  );
}

export default DownloadCopayCard;
