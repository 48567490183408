import React, { useContext } from 'react';
import { Callout } from 'components';
// import { Row, Col } from 'react-flexbox-grid';
import { appContext } from 'providers/appProvider';
import DownloadBlock from 'components/DownloadBlock';
import ColorBlock from 'components/Content/ColorBlock';

function NutritionDownloadFAQs({ className, ...props }) {
  const { appConfigs } = useContext(appContext);
  const brandNames = {
    NASCOBAL: appConfigs?.metaData?.brandNames?.nascobal,
    BARIACTIV: appConfigs?.metaData?.brandNames?.bariactiv,
    NUTRITION: appConfigs?.metaData?.brandNames?.nutrition,
  };
  return (
    <Callout className={`${className}`}>
      <ColorBlock yellow id="colorblock" className="mt-15 mb-20">
        <DownloadBlock
          DownloadFile="ND-FAQs-Update.pdf"
          LinkCaption="DOWNLOAD FAQs"
          ThumbnailImage="Nutrition_Direct_FAQ_thumb.png"
          className="btn-nd-download-faq yellow-button"
        >
          <h2 class="txt-uppercase">
            FREQUENTLY ASKED QUESTIONS ABOUT{' '}
            <span
              className="brandname-bariactiv"
              dangerouslySetInnerHTML={{
                __html: brandNames.NUTRITION,
              }}
            />
          </h2>
          <p>
            Learn about some of the most common questions and their answers.
          </p>
        </DownloadBlock>
      </ColorBlock>
    </Callout>
  );
}

export default NutritionDownloadFAQs;
