import React, { useContext, useEffect } from 'react';
import ScrollableAnchor, { goToAnchor } from 'react-scrollable-anchor';
import { Grid, Row, Col } from 'react-flexbox-grid';
import SourceEmitter from 'libs/emitter';
import { appContext } from 'providers/appProvider';
import { Layout, Seo } from 'components';
import ContentBlock from 'components/Content/ContentBlock';
import ColorBlock from 'components/Content/ColorBlock';
import NutritionDownloadFAQs from '../../../codes/components/callout-contents/ndpat/NutritionDownloadFAQs';
import DownloadNutritionDirectBrochure from '../../../codes/components/callout-contents/ndpat/DownloadNutritionDirectBrochure';
import DownloadCopayCard from '../../../codes/components/callout-contents/nspat/DownloadCopayCard';

import './patient-resources.scss';

import NDPatientResourcesHeroMobile from '../../../assets/images/ND-HCP-patient-resources-mobile.png';

let Subscription = null;

const patientresourcesPage = () => {
  const { appConfigs } = useContext(appContext);

  const indication = appConfigs?.siteOptions?.INDICATIONS?.nutritionHcp;
  // const metaData = appConfigs?.metaData?.nutritionHcp;

  const brandNames = {
    nascobal: appConfigs?.metaData?.brandNames?.nascobal,
    BARIACTIV: appConfigs?.metaData?.brandNames?.bariactiv,
    NUTRITION: appConfigs?.metaData?.brandNames?.nutrition,
  };

  /*  const buttonTracking = {
    category: 'Button-FAQs',
    action: 'clicked on Button-FAQs',
    label: 'Download ND FAQs',
  };
  const buttonTracking2 = {
    category: 'Button-copay',
    action: 'clicked on Button-copay',
    label: 'Download Copay card',
  }; */

  const triggerHashChange = () => {
    const currentHash = window?.location?.hash?.substring(
      1,
      window?.location?.hash?.length,
    );
    SourceEmitter.emit('HashChangedEventTrigger', currentHash);
  };

  useEffect(() => {
    window.addEventListener('hashchange', triggerHashChange, false);
    // Triggered from Logo Component
    Subscription = SourceEmitter.addListener('gotoTopTriggerEvent', data => {
      goToAnchor('top');
    });
    return () => {
      window.removeEventListener('hashchange', triggerHashChange, false);
      Subscription && Subscription.remove();
    };
  }, []);

  const pageTitle = 'NUTRITION DIRECT™ | Patient Resources';
  const pageDescription = 'Download resources for your appropriate patients';

  const renderProgramDetails = () => {
    return (
      <div>
        <ContentBlock
          id="about-nutrition-direct-block"
          className="no-padding-top margin-offset"
        >
          <Grid
            fluid
            style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 0 }}
          >
            <Row>
              <Col xs={12}>
                <div id="hero" className="hide-in-small">
                  <h2>PATIENT Resources</h2>
                </div>
                <div id="hero-mobile" className="show-in-small">
                  <img src={NDPatientResourcesHeroMobile} />
                </div>
              </Col>
            </Row>
            <ColorBlock white id="colorblock-patient-savings-and-support">
              <Row>
                <Col xs={12}>
                  <h2 className="strong">
                    <span
                      className="brandname-nutrition"
                      dangerouslySetInnerHTML={{
                        __html: brandNames.NUTRITION,
                      }}
                    />{' '}
                    patient support and savings
                  </h2>
                  <p>
                    Learn about patient educational materials, patient savings,
                    and specialty pharmacy support services for{' '}
                    <span
                      className="brandname-nutrition"
                      dangerouslySetInnerHTML={{
                        __html: brandNames.NUTRITION,
                      }}
                    />
                    .
                  </p>
                  <p className="strong" style={{ paddingBottom: 10 }}>
                    Resources to help patients stay on track:
                  </p>
                  <Row>
                    <Col xs={12} md={6}>
                      <ul className="first-list">
                        <li>Dosing instructions</li>
                        <li>Monthly reminders</li>
                        <li>Pharmacy services</li>
                        <li>Product information</li>
                        <li>Dedicated call center</li>
                      </ul>
                    </Col>
                    <Col xs={12} md={6}>
                      <ul>
                        <li>Recipe cards</li>
                        <li>FAQs</li>
                        <li>Patienteducation</li>
                        <li>Food diary</li>
                      </ul>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <p className="strong" style={{ paddingBottom: 10 }}>
                    Ongoing specialty pharmacy support services for physicians
                    and patients to ensure smooth processing and fulfillment:
                  </p>
                  <Row>
                    <Col xs={12} md={6}>
                      <ul className="smooth-processing-list first-list">
                        <li>
                          Calls patients to welcome them and confirm order
                          details
                        </li>
                        <li>
                          Ensures confirmed orders are shipped in 3 to 5
                          business days*
                        </li>
                        <li>
                          Emails confirmation of shipping and tracking
                          information
                        </li>
                        <li>
                          Manages monthly refills of nutritional supplements and
                          educational materials
                        </li>
                      </ul>
                    </Col>
                    <Col xs={12} md={6}>
                      <ul className="smooth-processing-list">
                        <li>
                          Facilitates switch from chewable to tablet/capsule
                          formulations
                        </li>
                        <li>
                          Addresses and processes insurance claims for your
                          patients
                        </li>
                      </ul>
                      <p className="footnote">
                        *Note: Incomplete prescription fax forms may cause
                        delivery delays.
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </ColorBlock>

            <Row id="colorblock-nd-hcp-resources-brochure">
              <Col xs={12}>
                <DownloadNutritionDirectBrochure className="color-block-lightorange" />
              </Col>
            </Row>
            <Row id="colorblock-nd-hcp-resources-copay">
              <Col xs={12}>
                <DownloadCopayCard className="color-block-lightorange" />
              </Col>
            </Row>
            <Row id="colorblock-nd-hcp-resources-faqs">
              <Col xs={12}>
                <NutritionDownloadFAQs className="color-block-lightorange" />
              </Col>
            </Row>
          </Grid>
        </ContentBlock>
      </div>
    );
  };

  const renderPage = () => {
    return (
      <div id="nutrition-hcp-patient-resources">
        {renderProgramDetails()}
        <ScrollableAnchor id="isi_anchor">
          <div />
        </ScrollableAnchor>
      </div>
    );
  };

  return (
    <Layout
      indication={indication}
      className="nutrition-hcp-patient-resources-page"
    >
      <Seo pageTitle={pageTitle} pageDescription={pageDescription} />
      <div className="body-content-container">
        <div className="body-content gutter-all">{renderPage()}</div>
      </div>
    </Layout>
  );
};

export default patientresourcesPage;
