import React, { useContext } from 'react';
import { Callout } from 'components';
// import { Row, Col } from 'react-flexbox-grid';
import { appContext } from 'providers/appProvider';
import DownloadBlock from 'components/DownloadBlock';
import ColorBlock from 'components/Content/ColorBlock';

function DownloadNutritionDirectBrochure({ className, ...props }) {
  const { appConfigs } = useContext(appContext);
  const brandNames = {
    NASCOBAL: appConfigs?.metaData?.brandNames?.nascobal,
    BARIACTIV: appConfigs?.metaData?.brandNames?.bariactiv,
    NUTRITION: appConfigs?.metaData?.brandNames?.nutrition,
  };
  return (
    <Callout className={`${className}`}>
      <ColorBlock orange id="colorblock" className="mt-15 mb-20">
        <DownloadBlock
          DownloadFile="NS-05831-ND-Patient-Brochure.pdf"
          LinkCaption="DOWNLOAD BROCHURE"
          ThumbnailImage="Nutrition_Direct_Patient_Brochure_thumb.png"
          className="btn-nd-download-brochure yellow-button"
          secondary
          secondaryDownloadFile="NS-05809-ND-Patient-Brochure-Spanish.pdf"
          secondaryLinkCaption="DOWNLOAD SPANISH BROCHURE"
          secondaryClassName="btn-nd-download-brochure-spanish yellow-button"
        >
          <h2 class="txt-uppercase">PATIENT BROCHURE</h2>
          <p>
            See how Nutrition Direct
            <span className="brand-trade-mark">&trade;</span>
             can help you get important nutritional supplements{' '}
            <br className="hide-mobile" />
            at an affordable cost and with the convenience of home delivery.
          </p>
        </DownloadBlock>
      </ColorBlock>
    </Callout>
  );
}

export default DownloadNutritionDirectBrochure;
